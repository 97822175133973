import { AccordionCard, StackPanel, StackItem, ActionLink, Anchor } from 'precise-ui'
import React from 'react';

interface Props {
  text: string;
  url: string;
}

interface State {
  readonly opened: boolean;
}

class ReferralCard extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  renderHeader() {
    return (
      <StackPanel className='ReferralRow'>
        <StackItem>{this.props.text}</StackItem>
        <StackItem style={{ textAlign: 'center',  color: '#ffffff', padding: '0 15px' }}>
          <ActionLink onClick={() => this.setState({ opened: !this.state.opened })}>
            {this.state.opened ? 'cerrar' : 'ver'}
          </ActionLink>
        </StackItem>
      </StackPanel>
    );
  }
  render() {
    return (
      <AccordionCard className='ReferralAccordion' header={this.renderHeader()} renderAction={() => null} opened={this.state.opened}>
        <div style={{ padding: '20px' }}>
          <Anchor href={this.props.url} target="_blank">{this.props.url}</Anchor>
        </div>
      </AccordionCard>
    );
  }
}

export default ReferralCard;