import React from 'react'
import './App.css'
import './ReferralCard.css'
import ReferralCard from './ReferralCard'
import { colors, Icon } from 'precise-ui'
import CookieConsent from 'react-cookie-consent'
import ReactWordcloud, { MinMaxPair, Word } from 'react-wordcloud'

function App() {

  const words = ['Bitcoin (BTC)', 'Ethereum (ETH)', 'Polkadot (DOT)', 'Binance Coin (BNB)', 'XRP (XRP)',
    'Cardano (ADA)', 'Dogecoin (DOGE)', 'Litecoin (LTC)', 'Chainlink (LINK)', 'VeChain (VET)', 'Stellar (XLM)', 'TRON (TRX)',
    'PancakeSwap (CAKE)', 'Holo (HOT)', 'Chilliz (CHZ)', 'Thether (USDT)', 'Uniswap (UNI)', 'Solana (SOL)', 'THETA (THETA)',
    'TERRA (LUNA)']
    .map((word: string) => {
      return { text: word, value: 10 }
    })

  const callbacks = {
    getWordTooltip: (word: Word) => `${word.text}`,
  }

  const options = {
    rotations: 4,
    rotationAngles: [-30, 30] as MinMaxPair
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="ReferralCard">
          <ReferralCard text={"Registrarse en Binance.com ahorrando hasta 10% en comisiones"}
                        url={"https://www.binance.com/es/register?ref=HL8G9RS9"}/>
          <div style={{ padding: "20px" }}/>
          <ReferralCard text={"Registrarse en Binance.com sin ahorro en comisiones"}
                        url={"https://www.binance.com/es/register"}/>

          <div style={{ padding: '2em', color: colors.purpleRed, fontSize: '1em' }}>
            <Icon name="Warning"/> Cuidado, las criptomonedas son inversiones de alto riesgo. Haz tu propio estudio
            antes de empezar a invertir.
          </div>
        </div>
      </header>
      <body>
      <ReactWordcloud words={words} callbacks={callbacks} options={options}/>
      </body>
      <footer>
        <div style={{ padding: '1em', color: colors.skyBlue, fontSize: '1em' }}>
          Dominios en venta: compracriptomoneda.es compracriptomoneda.com forocriptomoneda.es  forocriptomoneda.com
        </div>
        <div style={{ padding: '0.7em', color: colors.black, fontSize: '1em' }}>
          CompraCriptomoneda.com 2021
        </div>
      </footer>
      <CookieConsent
        location="bottom"
        buttonText="Acepto"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Esta web usa cookies para mejorar la experiencia del usuario
      </CookieConsent>
    </div>
  )
}

export default App
